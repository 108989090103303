import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ReactPlayer from 'react-player';
import { Box, Typography } from '@material-ui/core';
import axios from 'axios';
import SectionContainerLayout from '../../components/section-container-layout/section-container-layout';
export default function Press() {
  const [data, setData] = useState([]);
  const getData = async () => {
    await axios
      .get(`${process.env.GATSBY_CMS_API_URL}/streamings/VIMEOURL`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    try {
      getData();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Layout justLogo={true}>
      <SEO lang='en' title='Live events | Global Manufacturing & Industrialisation Summit' />
      <SectionContainerLayout title='GMIS2021 LIVE' isDivider>
        {data && data.name1 && data.url1 && (
          <Box mb={10} className='vimeoVideo'>
            <Box mb={2}>
              <Typography variant='h6'>{data.name1}</Typography>
            </Box>
            <ReactPlayer playing={false} className='videoPlayer' muted={false} url={data.url1} width='100%' />
          </Box>
        )}

        {data && data.name2 && data.url2 && (
          <Box mb={10} className='vimeoVideo'>
            <Box mb={2}>
              <Typography variant='h6'>{data.name2}</Typography>
            </Box>
            <ReactPlayer playing={false} className='videoPlayer' muted={false} url={data.url2} width='100%' />
          </Box>
        )}

        {data && data.name3 && data.url3 && (
          <Box mb={10} className='vimeoVideo'>
            <Box mb={2}>
              <Typography variant='h6'>{data.name3}</Typography>
            </Box>
            <ReactPlayer playing={false} className='videoPlayer' muted={false} url={data.url3} width='100%' />
          </Box>
        )}

        {data && data.name4 && data.url4 && (
          <Box className='vimeoVideo'>
            <Box mb={2}>
              <Typography variant='h6'>{data.name4}</Typography>
            </Box>
            <ReactPlayer playing={false} className='videoPlayer' muted={false} url={data.url4} width='100%' />
          </Box>
        )}
      </SectionContainerLayout>
    </Layout>
  );
}
